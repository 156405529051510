
import { defineComponent, onMounted, ref, reactive, nextTick } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { useRoute, useRouter } from "vue-router";
import { getPanel } from "@/api/encounter.api";
import { statementServices } from "@/api/services.api";
import { getDropdownsByType } from "@/api/dropdown.api";
import { useStore } from "vuex";
import DecimalInput from "@/components/ABilling/DecimalInput.vue";
import IntegerInput from "@/components/ABilling/IntegerInput.vue";
import Multiselect from "@vueform/multiselect";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { Service } from "@/modules/encounter/encounter.model";
import PaginationUi from "@/components/ABilling/Pagination.vue";
import { searchList } from "@/api/claim.api";
import { getPatient } from "@/api/patient.api";
import {
  PatientInsurance,
  selfPayer,
} from "../patientPayer/patientPayer.model";
import { searchDictInsuranceCompanys } from "@/api/code-master-insurance-company.api";
import debounce from "lodash.debounce";
import useFilters from "@/modules/common/useFilters";
import DateFloatComponent from "@/components/ABilling/DateFloatComponent.vue";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}
export interface StatusCode {
  code: string;
  description: string;
}

export interface Data {
  selectedItems: [];
  items: Service[];
  keyword: string;
  pagination: Pagination;
  orderBy: string;
  isDecr: boolean;
  panel: any;
  IsAdvancedOrderBy: boolean;
  filter: {
    allSelected: boolean;
    amount: number | null;
    statement: boolean;
    serviceDate: boolean;
    printStatement: boolean;
    zeroBalance: boolean;
    daysStatementSentGreaterThan: number | null;
    daysStatementSentLessThan: number | null;
    startStatementSentDate: Date | string | null;
    endStatementSentDate: Date | string | null;
    daysServiceDateGreaterThan: number | null;
    daysServiceDateLessThan: number | null;
    startServiceDate: Date | string | null;
    endServiceDate: Date | string | null;
    lastPaymentDays: number | null;
    includeAccountCredits: boolean;
    payOnOrBeforeXdays: number | null;
    statementDueDate: Date | string | null;
    noteForAllStatements: string | null;
    facility: [];
    provider: [];
    balanceDue: string[];
    status: string[];
  };
  dict: {
    patientPayers: PatientInsurance[];
    facilities: { id: string }[];
    providers: { id: string }[];
    actualPatientPayers: PatientInsurance[];
    actualFacilities: { id: string }[];
    paymentTypes: any[];
    adjustmentReasonCodes: any[];
    transactionTypes: any[];
    encounterStatusCodes: StatusCode[];
  };
}
export default defineComponent({
  name: "Statements",
  components: {
    PaginationUi,
    DecimalInput,
    Multiselect,
    IntegerInput,
    DateFloatComponent,
  },
  setup(props, ctx) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const orderList = [
      {
        name: "Patient",
        key: "patientName",
        isFilter: true,
        keyword: null,
        IsAdvancedOrderBy: true,
      },
      {
        name: "DOS",
        key: "serviceDate",
        isFilter: true,
        keyword: null,
        formType: "isDate",
      },
      {
        name: "Current Responsible Party",
        key: "responsibleParty",
        isFilter: true,
        keyword: null,
        IsAdvancedOrderBy: true,
        formType: "patientPayer",
      },
      {
        name: "Guarantor",
        key: "guarantor",
        isFilter: true,
        keyword: null,
        IsAdvancedOrderBy: true,
      },
      {
        name: "Facility",
        key: "encounter.facility.name",
        isFilter: true,
        keyword: null,
        formType: "facility",
      },
      {
        name: "Attending Provider",
        isFilter: true,
        key: "attendingProvider",
        keyword: null,
        formType: "attendingProvider",
        IsAdvancedOrderBy: true,
      },
      {
        name: "Rendering Provider",
        isFilter: true,
        key: "renderingProvider",
        keyword: null,
        formType: "renderingProvider",
        IsAdvancedOrderBy: true,
      },
      {
        name: "Encounter Status",
        key: "encounter.status",
        isFilter: true,
        keyword: null,
        IsAdvancedOrderBy: true,
      },
      {
        name: "Service",
        key: "service",
        isFilter: true,
        keyword: null,
        IsAdvancedOrderBy: true,
      },
      {
        name: "Total Charges",
        key: "totalCharges",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Payment",
        key: "totalPaymentsAdjustments",
        isFilter: true,
        keyword: null,
        IsAdvancedOrderBy: true,
      },
      {
        name: "Balance Due",
        key: "balanceDue",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Sent By",
        key: "statementLastSentBy",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Sent Date",
        key: "satetementLastSentDate",
        isFilter: true,
        keyword: null,
      },
    ];

    let organizationId = ref<string | null>("");

    let data = reactive<Data>({
      items: [],
      selectedItems: [],
      keyword: "",
      orderBy: "default",
      IsAdvancedOrderBy: true,
      isDecr: false,
      filter: {
        allSelected: false,
        statement: true,
        serviceDate: true,
        printStatement: true,
        zeroBalance: false,
        amount: null,
        daysStatementSentGreaterThan: null,
        daysStatementSentLessThan: null,
        startStatementSentDate: null,
        endStatementSentDate: null,
        daysServiceDateGreaterThan: null,
        daysServiceDateLessThan: null,
        startServiceDate: null,
        endServiceDate: null,
        lastPaymentDays: null,
        includeAccountCredits: false,
        payOnOrBeforeXdays: null,
        statementDueDate: null,
        noteForAllStatements: null,
        facility: [],
        provider: [],
        balanceDue: ["self"],
        status: ["BillPatient", "SubmittedToClearinghouse"],
      },
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 50,
      },
      panel: {},
      dict: {
        patientPayers: [],
        facilities: [],
        providers: [],
        actualPatientPayers: [],
        actualFacilities: [],
        paymentTypes: [],
        adjustmentReasonCodes: [],
        transactionTypes: [],
        encounterStatusCodes: [],
      },
    });

    async function cleanFilter() {
      data.keyword = "";
      data.orderBy = "default";
      data.IsAdvancedOrderBy = true;
      data.filter.facility = [];
      data.filter.provider = [];
      data.filter.balanceDue = ["self"];
      data.filter.status = ["BillPatient", "SubmittedToClearinghouse"];
      data.filter.daysStatementSentGreaterThan = null;
      data.filter.daysStatementSentLessThan = null;
      data.filter.startStatementSentDate = null;
      data.filter.endStatementSentDate = null;
      data.filter.amount = null;
      data.filter.lastPaymentDays = null;
      data.filter.daysServiceDateGreaterThan = null;
      data.filter.daysServiceDateLessThan = null;
      data.filter.startServiceDate = null;
      data.filter.endServiceDate = null;
      data.filter.zeroBalance = false;
      orderList.forEach((item) => {
        item.keyword = null;
      });
      await getAll();
    }

    onMounted(async () => {
      organizationId.value = getOrganization();
      setCurrentPageTitle("Statements");
      data.dict.paymentTypes = store.getters.allPaymentTypes;
      data.dict.transactionTypes = store.getters.allTransactionTypes;
      let adjustmentReasonCodes = await getDropdownsByType({
        type: "Adjustment Types",
      });
      data.dict.adjustmentReasonCodes = adjustmentReasonCodes.data;
      data.dict.encounterStatusCodes = store.getters.allEncounterStatuses;
      data.dict.encounterStatusCodes = data.dict.encounterStatusCodes
        .filter((x) => x.description != "Voided")
        .sort((a, b) => (a.description > b.description ? 1 : -1));
      data.dict.encounterStatusCodes = data.dict.encounterStatusCodes.sort(
        (a, b) => (a.code.toLowerCase() > b.code.toLowerCase() ? 1 : -1)
      );
      data.dict.patientPayers = await searchDictInsuranceCompanys({
        search: "",
      });
      data.dict.patientPayers.unshift(selfPayer);

      const panel = await getPanel();
      data.dict.facilities = panel.facilities;
      data.dict.providers = panel.providers.filter((provider) => {
        return provider.attending || provider.rendering;
      });

      const patient = route.query.patient as string;
      data.keyword = patient;

      await getAll();
    });

    async function selectFilter(header) {
      if (!header.key || !header.isFilter) {
        return;
      }
      if (data.orderBy == header.key) {
        data.isDecr = !data.isDecr;
      } else {
        data.isDecr = false;
      }
      data.orderBy = header.key;
      data.IsAdvancedOrderBy = header.IsAdvancedOrderBy;

      await search();
    }

    function getSortInfo(key) {
      if (data.orderBy == key) return true;
      return false;
    }

    async function clearSearch() {
      await nextTick();
      await search();
    }

    async function debounceSearch() {
      await debounce(search, useFilters().debounceMs)();
    }

    async function search() {
      await nextTick();
      data.pagination.currentPage = 1;
      await getAll();
    }

    async function getAll() {
      data.filter.allSelected = false;
      data.selectedItems = [];

      let order = data.orderBy;
      if (data.isDecr) {
        order = order + " Desc";
      }

      let orderBy: string[] | null = null;
      let advancedOrderBy: string | null = null;

      if (order && !data.IsAdvancedOrderBy) {
        orderBy = [order];
      } else {
        advancedOrderBy = order;
      }
      let constList = orderList
        .filter((item) => {
          return !item.IsAdvancedOrderBy;
        })
        .map((item) => {
          return item.key;
        });
      constList.push("patient.firstName");
      let request = {
        amount: data.filter.amount,
        pageNumber: data.pagination.currentPage,
        pageSize: data.pagination.pageSize,
        orderBy: orderBy,
        advancedOrderBy: advancedOrderBy,
        GlobalSearch: data.keyword,

        daysStatementSentGreaterThan: data.filter.daysStatementSentGreaterThan,
        daysStatementSentLessThan: data.filter.daysStatementSentLessThan,
        startStatementSentDate: data.filter.startStatementSentDate,
        endStatementSentDate: data.filter.endStatementSentDate,

        daysServiceDateGreaterThan: data.filter.daysServiceDateGreaterThan,
        daysServiceDateLessThan: data.filter.daysServiceDateLessThan,
        startServiceDate: data.filter.startServiceDate,
        endServiceDate: data.filter.endServiceDate,

        lastPaymentDays: data.filter.lastPaymentDays,
        includeAccountCredits: data.filter.includeAccountCredits,
        payOnOrBeforeXdays: data.filter.payOnOrBeforeXdays,
        statementDueDate: data.filter.statementDueDate,
        noteForAllStatements: data.filter.noteForAllStatements,
        facility: data.filter.facility,
        provider: data.filter.provider,
        balanceDue: data.filter.balanceDue,
        encounterStatuses: data.filter.status,
        zeroBalance: data.filter.zeroBalance,
      };

      const res = await statementServices(request);
      data.items = res.data;
      data.pagination.currentPage = res.currentPage;
      data.pagination.totalPages = res.totalPages;
      data.pagination.totalCount = res.totalCount;
      data.pagination.pageSize = res.pageSize;
    }

    async function pageChanged(page) {
      data.pagination.currentPage = page;
      await getAll();
    }

    async function pageSizeChanged(pageSize) {
      data.pagination.pageSize = pageSize;
      data.pagination.currentPage = 1;
      await getAll();
    }

    function viewEncounter(item) {
      router.push({ path: "/encounter/" + item.encounter.id });
    }

    async function viewClaimDetails(item) {
      const claims = await searchList({ encounterId: item.encounter.id });
      const claim = claims[0];
      let detailType = "/facilityClaimDetails";
      if (claim.encounterType == 1) detailType = "/professionalClaimDetails";
      router.push({ path: "/claim/" + claims[0].id + detailType });
    }

    async function selectAll() {
      if (data.filter.allSelected) {
        data.selectedItems = data.items as any;
      } else {
        data.selectedItems = [];
      }
    }

    function selectItem() {
      if (data.selectedItems.length == data.items.length) {
        data.filter.allSelected = true;
      } else {
        data.filter.allSelected = false;
      }
    }

    function expand(item) {
      item.expanded = !item.expanded;
    }

    function changeStatementType() {
      if (data.filter.statement) {
        data.filter.daysStatementSentGreaterThan = null;
        data.filter.daysStatementSentLessThan = null;
      } else {
        data.filter.startStatementSentDate = null;
        data.filter.endStatementSentDate = null;
      }
      data.filter.statement = !data.filter.statement;
    }

    function changeServiceDateType() {
      if (data.filter.serviceDate) {
        data.filter.daysServiceDateGreaterThan = null;
        data.filter.daysServiceDateLessThan = null;
      } else {
        data.filter.startServiceDate = null;
        data.filter.endServiceDate = null;
      }
      data.filter.serviceDate = !data.filter.serviceDate;
    }

    function changePrintStatementType() {
      if (data.filter.statement) {
        data.filter.payOnOrBeforeXdays = null;
      } else {
        data.filter.statementDueDate = null;
      }
      data.filter.printStatement = !data.filter.printStatement;
    }

    async function createStatements() {
      let statements = [] as any;
      let patients = [] as any;

      data.selectedItems
        .map((i: any) => {
          return i.encounter.patient;
        })
        .forEach((item) => {
          if (
            !patients.find((i: any) => {
              return i.id == item.id;
            })
          ) {
            patients.push(item);
          }
        });

      for (const patient of patients) {
        let fullPatient = await getPatient(patient.id);
        let guarantors = [] as any;
        data.selectedItems
          .filter((item: any) => {
            return item.encounter.patientId == patient.id;
          })
          .map((i: any) => {
            return i.guarantor;
          })
          .forEach((item) => {
            if (item == null) {
              const nullVal = guarantors.find((i: any) => {
                return i == null;
              });
              if (nullVal !== null) {
                guarantors.push(item);
              }
            } else if (
              !guarantors.find((i: any) => {
                return i != null && i.id == item.id;
              })
            ) {
              guarantors.push(item);
            }
          });

        for (const guarantor of guarantors) {
          let services = data.selectedItems.filter((d: any) => {
            return (
              d.encounter.patientId == patient.id &&
              ((d.guarantorId == null && guarantor == null) ||
                (guarantor && d.guarantorId == guarantor.id))
            );
          });
          let statement = {
            services: services,
            includeAccountCredits: data.filter.includeAccountCredits,
            payOnOrBeforeXdays: data.filter.payOnOrBeforeXdays,
            statementDueDate: data.filter.statementDueDate,
            noteForAllStatements: data.filter.noteForAllStatements,
            patientId: patient.id,
            patient: fullPatient,
            guarantorId: guarantor?.id,
            guarantor: guarantor,
          };
          console.log(guarantor);
          statements.push(statement);
        }
      }

      ctx.emit("createStatements", statements);
    }

    function getStatusHeader(code) {
      if (data.dict.encounterStatusCodes) {
        const status = data.dict.encounterStatusCodes.find((item) => {
          return item.code == code;
        });
        if (status) {
          return status.description;
        }
      }
      return code;
    }

    return {
      viewEncounter,
      viewClaimDetails,
      selectAll,
      organizationId,
      clearSearch,
      data,
      orderList,
      debounceSearch,
      search,
      getSortInfo,
      selectFilter,
      cleanFilter,
      pageChanged,
      pageSizeChanged,
      changeStatementType,
      changeServiceDateType,
      changePrintStatementType,
      selectItem,
      createStatements,
      expand,
      getStatusHeader,
    };
  },
});
