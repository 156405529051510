
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { defineComponent, onMounted, ref, reactive } from "vue";
import { addStatement } from "@/api/statement.api";
import html2pdf from "html2pdf.js";
import { useStore } from "vuex";
import { getStatementSettings } from "@/api/statementSettings.api";
import IntegerInput from "@/components/ABilling/IntegerInput.vue";
import moment from "moment";
import DateFloatComponent from "@/components/ABilling/DateFloatComponent.vue";

export interface Data {
  logoUrl?: any;
  claim: any;
  isLoaded: boolean;
  statementSettings?: any;
  filter: {
    printStatement: boolean;
    includeAccountCredits: false;
    payOnOrBeforeXdays: null;
    statementDueDate: null;
    noteForAllStatements: null;
  };
  dict: {
    paymentTypes: any[];
    transactionTypes: any[];
  };
  selectedFacilityIncomplete: boolean;
}

export default defineComponent({
  name: "StatementPage",
  components: { IntegerInput, DateFloatComponent },
  props: ["statements"],
  setup(props, ctx) {
    const store = useStore();
    let organizationId = ref<string | null>("");
    let data = reactive<Data>({
      claim: undefined,
      isLoaded: false,
      statementSettings: {},
      filter: {
        printStatement: false,
        includeAccountCredits: false,
        payOnOrBeforeXdays: null,
        statementDueDate: null,
        noteForAllStatements: null,
      },
      dict: {
        paymentTypes: [],
        transactionTypes: [],
      },
      selectedFacilityIncomplete: false,
    });

    const mainPdf = ref<null | any>(null);
    let elements = ref(null);

    onMounted(async () => {
      setCurrentPageTitle("Customize Statements");
      data.statementSettings = await getStatementSettings();
      if (!data.statementSettings) {
        data.statementSettings = {};
      } else {
        data.logoUrl = "data:image/png;base64," + data.statementSettings.logo;
      }
      data.dict.transactionTypes = store.getters.allTransactionTypes;
      data.isLoaded = true;
    });

    function createMainPdf() {
      //mainPdf.value?.generatePdf();
    }

    function resetRequired() {
      if (!data.filter.statementDueDate && !data.filter.payOnOrBeforeXdays) {
        data.selectedFacilityIncomplete = true;
      } else {
        data.selectedFacilityIncomplete = false;
      }
    }

    async function generateReport() {
      var today = moment.utc(getUTCnow()).format("DD_MMM_yyyy");
      if (data.filter.statementDueDate || data.filter.payOnOrBeforeXdays) {
        data.selectedFacilityIncomplete = false;
        var opt = {
          margin: 1,
          image: { type: "jpeg", quality: 2.0 },
          filename: "statement_" + today + ".pdf",
          jsPDF: { format: "a4", orientation: "portrait" },
          pagebreak: { mode: ["legacy", "css"] },
          html2canvas: { scale: 2 },
        };
        var main = document.getElementById("mainPdf");
        html2pdf()
          .set(opt)
          .from(main)
          .toPdf()
          .get("pdf")
          .then(function (pdf) {
            const f = pdf.output("bloburl");
            let link = document.createElement("a");
            link.target = "_blank";
            link.href = pdf.output("bloburl");
            link.download = "statement_" + today + ".pdf";
            link.click();
            window.open(pdf.output("bloburl"), "_blank");
          });

        //Part 1
        props.statements.forEach((statement, index) => {
          var element1 = document.getElementById("statement" + index);
          html2pdf()
            .set(opt)
            .from(element1)
            .toPdf()
            .get("pdf")
            .output("blob")
            .then(function (pdfObj) {
              var file = pdfObj;
              var formData = new FormData();
              for (var i = 0; i < statement.services.length; i++) {
                formData.append("services[]", statement.services[i].id);
              }
              formData.append("patientId", statement.patientId);
              if (statement.guarantorId)
                formData.append("guarantorId", statement.guarantorId);
              formData.append("file", file);
              formData.append("sentDate", getUTCnow().toISOString());
              let user = store.getters.currentUser;
              formData.append("sentBy", `${user.firstName} ${user.lastName}`);
              addStatement(formData);
            });
        });
        createMainPdf();
      } else {
        data.selectedFacilityIncomplete = true;
      }
    }

    function getUTCnow() {
      var date = new Date();
      const d = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
      );
      return d;
    }

    function getTotals(statement) {
      let totals = 0.0;
      statement.services.forEach((item) => {
        totals += item.balanceDue;
      });
      return totals;
    }

    function getPaymentsTotals(statement) {
      let totals = 0.0;
      statement.services.forEach((item) => {
        totals += item.totalPaymentsAdjustments;
      });
      return totals;
    }

    function getDate() {
      if (data.filter.payOnOrBeforeXdays) {
        let today = getUTCnow();
        var result = new Date(today);
        const days = data.filter.payOnOrBeforeXdays as any;
        result.setDate(result.getDate() - days);
        return result;
      }
    }

    function changePrintStatementType() {
      if (data.filter.printStatement) {
        data.filter.payOnOrBeforeXdays = null;
      } else {
        data.filter.statementDueDate = null;
      }
      data.filter.printStatement = !data.filter.printStatement;
    }

    function getOrderedServices(statement) {
      return statement.services.sort(function (a: any, b: any) {
        return (
          new Date(b.serviceDate).getTime() - new Date(a.serviceDate).getTime()
        );
      });
    }

    function backToList() {
      ctx.emit("back");
    }

    function formatPhone(phone: string) {
      if (phone)
        return `(${phone.substring(0, 3)}) ${phone.substring(
          3,
          6
        )}-${phone.substring(6, 10)}`;
      else return "";
    }

    return {
      props,
      generateReport,
      organizationId,
      mainPdf,
      elements,
      createMainPdf,
      changePrintStatementType,
      resetRequired,
      getUTCnow,
      getTotals,
      getPaymentsTotals,
      getDate,
      backToList,
      getOrderedServices,
      data,
      formatPhone,
    };
  },
});
