import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

export const url = "api/v1/claims";

export async function getClaims() {
  const request = {
    keyword: "",
    pageNumber: 0,
    pageSize: 100,
    orderBy: ["id"],
  };
  return await ApiService.post(url + "/search", request as never)
    .then((res) => {
      const items = res.data.data;
      //TODO DEBT
      items.forEach((item: any) => {
        if (item.submissionDate)
          item.submissionDate = new Date(item.submissionDate);
        item.claimLineItems.forEach((claimLineItem: any) => {
          claimLineItem.serviceDate = new Date(claimLineItem.serviceDate);
        });
      });

      return items;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function searchList(request) {
  return await ApiService.post(url + "/searchList", request as never)
    .then((res) => {
      const data = res.data;

      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function searchClaims(request) {
  return await ApiService.post(url + "/search", request as never)
    .then((res) => {
      const data = res.data;
      //TODO DEBT
      data.data.forEach((item: any) => {
        if (item.submissionDate)
          item.submissionDate = new Date(item.submissionDate);
        item.claimLineItems.forEach((claimLineItem: any) => {
          claimLineItem.serviceDate = new Date(claimLineItem.serviceDate);
        });
      });

      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function searchClaimsForPayment(request) {
  return await ApiService.post(url + "/searchForPayment", request as never)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function addClaim(encounterId) {
  return await ApiService.post(url, {
    encounterId: encounterId,
  } as never).catch(({ response }) => {
    processErrors(response);
  });
}
//reviewDate
export async function reviewDate(claimId, date) {
  return await ApiService.post(url + "/reviewDate", {
    id: claimId,
    reviewDate: date,
  } as never).catch(({ response }) => {
    processErrors(response);
  });
}

export async function loadClaimReviewDate(request) {
  return await ApiService.post(url + "/loadReviewDates", request as never)
    .then((res) => {
      return res.data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}


export async function getClaim(claimId) {
  return await ApiService.get(url + "/" + claimId)
    .then((res) => {
      const item = res.data;
      //TODO DEBT
      item.claimLineItems.forEach((claimLineItem: any) => {
        claimLineItem.serviceDate = new Date(claimLineItem.serviceDate);
      });

      return item;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function getClaimForPayment(claimId) {
  return await ApiService.get(url + "/forPayment/" + claimId)
    .then((res) => {
      const item = res.data;
      //TODO DEBT
      item.claimLineItems.forEach((claimLineItem: any) => {
        claimLineItem.serviceDate = new Date(claimLineItem.serviceDate);
      });

      return item;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function updateClaim(claim) {
  if (claim) {
    return await ApiService.put(url + "/" + claim.id, claim as never)
      .then((res) => {
        return res;
      })
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}

export async function getFilters() {
  return await ApiService.post(url + "/getFilters", {})
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function deleteClaim(claim) {
  if (claim) {
    await ApiService.delete(url + "/" + claim?.id)
      .then()
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}

export async function postAction(claimActionRequest) {
  return await ApiService.post(url + "/postAction", claimActionRequest as never)
    .then((res) => {
      return res.data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function updateBillingIndicator(claimId, billingIndicator) {
  return await ApiService.post(url + "/UpdateBillingIndicator", {id: claimId, billingIndicator: billingIndicator} as never)
    .then((res) => {
      return res.data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function updatePaymentInfo(claimWithPaymentInfo) {
  return await ApiService.post(url + "/updatePaymentInfo", claimWithPaymentInfo as never)
    .then((res) => {
      return res.data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function checkClaimTransactions(
  checkClaimTransactionsRequest
) {
  return await ApiService.post(
    url + "/checkClaimTransactions",
    checkClaimTransactionsRequest as never
  )
    .then((res) => {
      return res.data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function waystarFieldsUpdate(
  checkClaimTransactionsRequest
) {
  return await ApiService.post(
    url + "/WaystarFieldsUpdate",
    checkClaimTransactionsRequest as never
  )
    .then((res) => {
      return res.data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}
